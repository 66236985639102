<template>
  <button
    class="no-tap-highlight group absolute right-4 top-[17px] z-10 mx-auto h-6 w-6 cursor-pointer self-center bg-transparent md:right-8 md:top-10 md:h-10 md:w-10 lg:hidden"
    :class="isMenuVisible && $style.isVisible"
    :title="isMenuVisible ? $t('menu.hide') : $t('menu.show')"
    type="button"
    @click="menuToggle"
  >
    <div class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
      <span v-for="idx in 3" :key="idx" :class="$style.line"></span>
    </div>
  </button>
</template>

<script setup lang="ts">
const { menuToggle } = useMenu()
const isMenuVisible = useState('menu')
</script>

<style lang="postcss" module>
.line {
  --line-thickness: 2px;
  --line-width: 20px;
  --line-margin: 4px;
  --line-color: black;

  @apply my-[var(--line-margin)] block h-[var(--line-thickness)] w-[var(--line-width)] origin-center transform-gpu rounded-full bg-[var(--line-color)] transition-transform duration-300 ease-quart-in-out;
}

.isVisible {
  .line {
    &:nth-child(1) {
      @apply translate-y-1.5px rotate-[135deg];
    }

    &:nth-child(2) {
      @apply scale-0;
    }

    &:nth-child(3) {
      @apply -translate-y-1.5px -rotate-[135deg];
    }
  }
}
</style>
