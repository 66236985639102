<template>
  <header
    ref="headerRef"
    class="lg:px-page z-10 h-[var(--header-height-sm)] w-full transform-gpu bg-white will-change-transform md:h-auto md:py-9"
    :class="[
      isAnimated && 'transition-transform duration-700 ease-in-out',
      isFixed ? 'fixed' : 'absolute translate-y-0 duration-0',
      isPinned ? 'translate-y-0' : '-translate-y-full'
    ]"
  >
    <div class="flex h-full items-center justify-between" @mouseleave="handleLeave">
      <div class="z-2 inline-flex self-center">
        <Logo class="ml-4px md:ml-10 lg:ml-0" />
      </div>
      <div>
        <MenuList :data="data.allMenuItems" />
      </div>
      <div class="z-[1] hidden md:block">
        <UserLinks :data="data.allUserLinks" />
      </div>
    </div>
    <MenuButton />
  </header>
</template>

<script setup lang="ts">
import { useAppStore } from '~/stores/AppStore'
import screens from '#tailwind-config/theme/screens'
const breakpoints = useBreakpoints(screens)
const isScreenLg = breakpoints.greaterOrEqual('lg')

const store = useAppStore()

const isMenuVisible = useState('menu')
const { menuHide } = useMenu()

const headerRef = ref<HTMLElement | null>(null)

const isPinned = ref(true)
const isAnimated = ref(false)
const isFixed = ref(false)

const lastScrollY = ref(0)

defineProps({
  data: {
    type: Object,
    default: () => {}
  }
})

const { y: scrollY, directions } = useScroll(process.client ? window : null, {
  onScroll: () => {
    if (headerRef.value === null) {
      return
    }

    if (scrollY.value > headerRef.value.offsetHeight) {
      isFixed.value = true
    } else {
      isFixed.value = false
    }

    // Add animation only after header leaves the screen
    if (scrollY.value > headerRef.value.offsetHeight * 1.5) {
      isAnimated.value = true
    } else {
      isAnimated.value = false
    }

    const tolerance = 5
    const distance = Math.abs(lastScrollY.value - scrollY.value)

    if (
      scrollY.value > window.innerHeight &&
      directions.top &&
      store.isStickyHeaderPinEnabled &&
      distance > tolerance
    ) {
      isPinned.value = true
    } else if (distance > tolerance) {
      isPinned.value = false
    }

    lastScrollY.value = scrollY.value

    // Handle Menu
    if (isMenuVisible.value) {
      menuHide()
    }
  }
})

function handleLeave() {
  if (isScreenLg.value) {
    menuHide()
  }
}

onMounted(() => {
  store.setStickyHeader(true)
})
</script>

<style lang="postcss">
:root {
  --header-height-sm: 60px;
  --header-height-md: 7.25rem;
}
</style>
