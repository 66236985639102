<template>
  <div
    class="w-full border-b border-neutral-50 lg:w-fit lg:border-none"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <component
      :is="data.reference !== null ? NuxtLink : data.externalLink ? NuxtLink : 'button'"
      :to="
        data.reference
          ? localePath(`/${data.reference.slug}/`)
          : data.externalLink
          ? data.externalLink
          : undefined
      "
      class="relative flex w-full justify-between py-5px text-nav-20-medium-px lg:my-0 lg:w-fit lg:justify-start lg:py-1 lg:!text-main-menu"
      :class="[
        data.reference !== null || data.externalLink
          ? 'cursor-pointer'
          : Array.isArray(data.blocks) && data.blocks.length
          ? 'cursor-pointer'
          : 'cursor-auto'
      ]"
      @click="onClickParent"
    >
      {{ data.title }}
      <Icon
        v-if="Array.isArray(data.blocks) && data.blocks.length"
        name="chevron-down-general"
        :class="[isSubmenuVisible && isCurrentSubmenu === idx ? 'rotate-180' : 'rotate-0']"
        class="pointer-events-none ml-2 mt-0.5 !h-3px !w-3px lg:!h-3 lg:!w-3"
      />
    </component>

    <div
      v-show="isSubmenuVisible && isCurrentSubmenu === idx"
      ref="submenuRef"
      class="lg:top-22 relative w-full lg:absolute lg:left-0 lg:w-full"
      :style="styleOffset"
    >
      <div
        class="gap-x-default py-10 transition-opacity duration-300 sm:flex md:py-12 lg:w-fit lg:justify-center lg:gap-x-24 xl:gap-x-20"
        :class="isMenuBackground ? 'opacity-100' : 'opacity-0'"
      >
        <component
          :is="resolveMenuBlockComponent(block.__typename as string)"
          v-for="block in data.blocks"
          :key="block.__typename"
          :data="block"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAppStore } from '~/stores/AppStore'
import screens from '#tailwind-config/theme/screens'
import { MenuItemRecord } from '~/types/generated'

const isSubmenuVisible = useState('submenu')
const isCurrentSubmenu = useState('currentSubmenu')
const isMenuBackground = useState('menuBackground')

const NuxtLink = resolveComponent('NuxtLink')
const { menuShow, menuHide, submenuToggle, submenuSetIdx } = useMenu()

const localePath = useLocalePath()

const { pointerType } = usePointer()

const { resolveMenuBlockComponent } = useResolveComponent()

const breakpoints = useBreakpoints(screens)
const isLg = breakpoints.greaterOrEqual('lg')

const store = useAppStore()
const submenuRef = ref<HTMLElement | null>(null)

const { height: subMenuHeight } = useElementBounding(submenuRef)

const props = defineProps({
  data: {
    type: Object as PropType<MenuItemRecord>,
    default: () => {}
  },
  idx: {
    type: Number,
    default: 0
  },
  menuOffset: {
    type: Number,
    default: 0
  }
})

const styleOffset = computed(() => `padding-left: ${props.menuOffset.toFixed()}px`)

// eslint-disable-next-line func-call-spacing
const emit = defineEmits<{
  (event: 'click'): void
}>()

function onClickParent() {
  if (props.data.blocks.length === 0 && !isLg.value) {
    menuHide()
  }

  if (props.data.blocks.length && !isLg.value) {
    submenuToggle(props.idx)
    emit('click')
  }
}

function onMouseEnter() {
  if (pointerType.value !== 'mouse') {
    return
  }

  if (isMenuBackground.value && props.idx === isCurrentSubmenu.value) {
    menuHide()
    return
  }

  if (isLg.value && props.data.blocks.length === 0) {
    menuHide()
    return
  }

  if (isLg.value) {
    menuShow(props.idx)

    useTimeoutFn(() => {
      store.setMenuHeight(Math.round(subMenuHeight.value))
    }, 100)
  }
}

function onMouseLeave() {
  if (pointerType.value !== 'mouse' || props.data.blocks.length === 0) {
    return
  }

  if (isLg.value) {
    submenuSetIdx(props.idx)
  }
}
</script>
