<template>
  <Html :lang="langAttr" :dir="head.htmlAttrs.dir">
    <Head>
      <Title>{{ title }}</Title>
      <template v-for="link in head.link" :key="link.id">
        <Link :id="link.id" :rel="link.rel" :href="link.href" :hreflang="link.hreflang" />
      </template>
      <template v-for="meta in head.meta" :key="meta.id">
        <Meta :id="meta.id" :property="meta.property" :content="meta.content" />
      </template>
    </Head>
    <Body>
      <div>
        <MenuBackDrop />
        <MenuBackground />
        <Header :data="data" />
        <UserLinks :data="data.allUserLinks" class="z-20 md:!hidden" />
        <main class="flow-root pt-[var(--header-height-sm)] md:pt-[var(--header-height-md)]">
          <slot />
        </main>
        <LazyFooter :data="data" />
        <ClientOnly>
          <LazyVoucherActivationModal v-if="store.loadVoucherActivation" />
          <!--
          <LazyWelcomeModal />
          -->
          <LazyCookieConsent v-if="config.public.country !== 'CH'" />
        </ClientOnly>
      </div>
    </Body>
  </Html>
</template>

<script setup>
// import { CountryCode } from '~/types/locales'
// import { LocalStorageKey } from '~/types/localStorage'
import { useModalStore } from '~/stores/ModalStore'
import locales from '~/i18n/locales'

const route = useRoute()
const { t } = useI18n()
const config = useRuntimeConfig()
// const countryCode = useCountryCode()
const store = useModalStore()
const head = useLocaleHead({
  addDirAttribute: false,
  identifierAttribute: 'id'
})
const { $posthog } = useNuxtApp()

const { locale } = useI18n()

const langAttr = computed(() => locales.find(l => l.code === locale.value).lang)

const title = computed(() => t('layouts.title', { title: t(route.meta.title ?? 'TBD') }))

defineProps({
  data: {
    type: Object,
    default: () => {}
  }
})

watch(store, store => {
  if ($posthog) {
    const posthog = $posthog()
    posthog.set_config({
      autocapture: !store.showVoucherActivation
    })
  }
})

onMounted(() => {
  if ($posthog) {
    window.addEventListener('beforeunload', () => {
      const posthog = $posthog()
      posthog.capture('$pageleave', null, { transport: 'sendBeacon' })
    })
  }
})

/*
const handleWelcomeModal = async () => {
  if (process.client) {
    const { data: ipData } = await useFetch(
      `https://extreme-ip-lookup.com/json/?key=${config.public.extremeIpApiKey}`
    )
    if (
      ipData.value?.countryCode === CountryCode.CH.toUpperCase() &&
      countryCode === CountryCode.DE &&
      !localStorage.getItem(LocalStorageKey.WelcomeModal)
    ) {
      setTimeout(() => {
        store.setShowWelcome(true)
      }, 500)
    }
  }
}

onMounted(() => {
  nextTick(handleWelcomeModal)
})
*/
</script>
