<template>
  <Transition @beforeEnter="beforeEnter" @enter="enter" @afterEnter="afterEnter">
    <nav
      v-show="isMenuVisible"
      class="absolute left-0 top-0 z-1 h-screen w-full pt-[var(--header-height-sm)] lg:static lg:left-auto lg:top-auto lg:!block lg:h-auto lg:w-full lg:p-0 lg:!opacity-100"
    >
      <div
        ref="menuRef"
        class="flex h-full flex-col overflow-scroll px-4px pb-80 pt-4 lg:mx-auto lg:h-auto lg:w-full lg:flex-row lg:justify-center lg:gap-x-16 lg:overflow-visible lg:p-0"
      >
        <MenuListItem
          v-for="(item, idx) in data"
          :key="idx"
          :idx="idx"
          :menuOffset="menuLeft"
          :data="item"
          @click="handleClickItem"
        />
      </div>
    </nav>
  </Transition>
</template>

<script setup lang="ts">
import { useElementBounding } from '@vueuse/core'
import gsap from 'gsap'
import screens from '#tailwind-config/theme/screens'
import { MenuItemRecord } from '~/types/generated'

const { menuHide } = useMenu()

const isMenuVisible = useState('menu')
const breakpoints = useBreakpoints(screens)
const isScreenLg = breakpoints.greaterOrEqual('lg')

const menuRef = ref<HTMLElement | null>(null)
const { left: menuLeft } = useElementBounding(menuRef)

defineProps({
  data: {
    type: Array as PropType<MenuItemRecord[]>,
    default: () => []
  }
})

const beforeEnter = (el: Element) => {
  if (isScreenLg.value) {
    return
  }
  ;(el as HTMLElement).style.opacity = '0'
}

const enter = (el: Element, done: () => void) => {
  if (isScreenLg.value) {
    return
  }
  gsap.to(el, {
    opacity: 1,
    delay: 0.16,
    duration: 0.6,
    ease: 'power3.out',
    onComplete: () => {
      done()
    }
  })
}

const afterEnter = (el: Element) => {
  if (isScreenLg.value) {
    return
  }
  ;(el as HTMLElement).style.opacity = ''
}

function handleClickItem() {
  if (menuRef.value) {
    menuRef.value.scrollTop = 0
  }
}

// Handle menu on resize
const handleResize = useDebounceFn(() => {
  if (isScreenLg.value && isMenuVisible.value) {
    menuHide()
  }
}, 400)

onMounted(() => {
  window.addEventListener('resize', handleResize)
})

onUnmounted(() => {
  window.removeEventListener('resize', handleResize)
})
</script>
