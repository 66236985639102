<template>
  <div class="pointer-events-none fixed left-0 top-0 z-10 h-full w-full overflow-hidden">
    <div
      ref="menuBackgroundRef"
      class="absolute left-0 top-0 h-full w-full -translate-y-full overflow-hidden bg-white md:min-h-[var(--menu-min-height-md)] lg:min-h-[var(--menu-min-height-lg)]"
      :style="{ maxHeight: isScreenLg ? `${rem(store.mainMenuHeight + 96)}` : 'none' }"
    />
  </div>
</template>

<script setup lang="ts">
import { gsap } from 'gsap'
import { CustomEase } from 'gsap/CustomEase'
import { useAppStore } from '~/stores/AppStore'
import screens from '#tailwind-config/theme/screens'
import { rem } from '~/utils/index'

const breakpoints = useBreakpoints(screens)
const isScreenLg = breakpoints.greaterOrEqual('lg')

const { setMenuBackground } = useMenu()
const isVisible = useState('menu')

const store = useAppStore()

const menuBackgroundRef = ref<HTMLElement | null>(null)
const tween = ref<gsap.core.Tween | null>(null)

onMounted(() => {
  gsap.registerPlugin(CustomEase)
  CustomEase.create('quart-out', '0.165, 0.84, 0.44, 1')
})

watch(
  () => [isVisible.value, isScreenLg.value, store.mainMenuHeight],
  () => {
    if (!menuBackgroundRef.value) {
      return
    }
    tween.value?.kill()
    tween.value = gsap.to(menuBackgroundRef.value, {
      duration: 0.8,
      ease: 'quart-out',
      y: isVisible.value && (!isScreenLg.value || store.mainMenuHeight) ? 0 : '-100%',
      onUpdate: () => {
        const currentY = gsap.getProperty(menuBackgroundRef.value, 'y')
        setMenuBackground(Number(currentY) > (isVisible.value ? -50 : -20))
      }
    })
  },
  { immediate: true }
)
</script>

<style lang="postcss">
:root {
  --menu-min-height-md: 40rem;
  --menu-min-height-lg: 36rem;
}
</style>
