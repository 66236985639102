<template>
  <nav
    v-show="isMenuVisible"
    class="bg-[rgba(255, 255, 255, 0.11)] fixed bottom-0 left-0 z-1 flex w-full flex-col gap-2 rounded-t-[20px] px-4 pb-9 pt-5 backdrop-blur-[30px] md:static md:bottom-auto md:top-auto md:mr-24 md:!flex md:translate-y-0 md:flex-row md:gap-5 md:bg-transparent md:p-0 md:backdrop-blur-0 lg:mr-0"
  >
    <Button
      v-for="item in data"
      :key="item.id"
      :to="
        item.reference
          ? localePath(`/${item.reference.slug}`)
          : item.externalLink
          ? item.externalLink
          : undefined
      "
      :target="item.externalLink ? '_blank' : undefined"
      :variant="item.style as ButtonVariant"
      class="!w-full !text-main-menu transition-opacity hover:opacity-70 md:!w-auto"
      :class="[
        {
          'mb-4 md:mb-0': item.style === 'link',
          '!px-0': item.style === 'ghost'
        }
      ]"
      noPrefetch
      @click="menuHide"
    >
      <Icon v-if="item.style === 'ghost'" name="pin-general" class="mr-2 !h-5 !w-4" />
      {{ item.title }}</Button
    >
    <Button
      v-if="config.public.country === 'CH'"
      class="!w-full !text-main-menu transition-opacity hover:opacity-70 md:!w-auto"
      noPrefetch
      @click="showVoucherActivation"
      >{{ $t('voucherActivation.userLink') }}</Button
    >
  </nav>
</template>

<script setup lang="ts">
import { ButtonVariant } from '~/components/action/Button.vue'
import { UserLinkRecord } from '~/types/generated'
import { useModalStore } from '~/stores/ModalStore'

const config = useRuntimeConfig()
const store = useModalStore()

const isMenuVisible = useState('menu')
const { menuHide } = useMenu()
const localePath = useLocalePath()

defineProps({
  data: {
    type: Array as PropType<UserLinkRecord[]>
  }
})

function showVoucherActivation() {
  menuHide()
  store.setShowVoucherActivation(true)
}
</script>
