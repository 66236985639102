<template>
  <Transition name="backdrop">
    <div
      v-show="isVisible"
      class="fixed left-0 top-0 z-10 h-screen w-full transform-gpu bg-[#d9d9d9] bg-opacity-[0.1] backdrop-blur-lg"
    ></div>
  </Transition>
</template>

<script setup lang="ts">
const isVisible = useState('menu')
</script>

<style lang="postcss">
.backdrop-leave-active {
  transition: all 400ms var(--ease-out-quart);
}

.backdrop-enter-active {
  transition-delay: 150ms;
}

.backdrop-enter-from,
.backdrop-leave-to {
  backdrop-filter: blur(0);
}
</style>
